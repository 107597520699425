<template>
  <header class="v2 sticky">
    <div class="banner" v-if="banner">
      Powered By <img src="../assets/avris-logo-white-LR.png">
    </div>
    <div :style="{ 'margin-top': (banner ? 'var(--size-banner)' : '0px')}" style="display:flex;">
      <router-link to="/upcoming" class="logo"></router-link>
      <div class="slot">
        <slot />
      </div>
      <nav>
        <img class="wizard" src="../assets/information.svg" title="View &quot;Getting Started&quot; steps" @click="$store.commit('showGettingStarted');" />
        <div class="menu" ref="menu" @click="menuOpen = !menuOpen">
          <span class="arrow"></span>
          {{$store.getters.userFullName}} <span class="role" v-if="role">{{role}}</span>
        </div>      
        <div class="menuitems" ref="menuitems" v-if="menuOpen">
          <div v-if="avrisyncMvpToggleAvailable" style="margin-bottom: 10px; overflow: auto;">
            <label style="font-size:16px;">Use AvriSync: </label>
            <ToggleButton v-if="$store.getters.isAdmin || $store.getters.isOrgAdmin" style="margin: 8px 0 0 10px;" title="Toggle AvriSync" :labels="false" v-model="toggle" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" :sync="true" @change="setToggle" @click.prevent="console.log('wtf')" /> 
          </div>
          <router-link to="/profile">Edit Profile</router-link>
          <!-- <router-link to="/users">Manage Users</router-link> -->
          <a @click="logout">Log Out</a>
        </div>

      </nav>      
    </div>
  </header>
</template>

<script>
import {AUTH_LOGOUT} from "../store/actions/auth";

export default {
  name: 'Header2', 
  props: {
    banner: Boolean
  },
  data: function(){
    return{
      menuOpen: false,
      toggle: this.$store.getters.avrisyncMvp
    }
  },
  computed: {
    role(){
      if(this.$store.getters.isAdmin)
        return '[Admin]'
      if(this.$store.getters.isOrgAdmin)
        return '[OrgAdmin]'
      return null
    },
    avrisyncMvpToggleAvailable(){      
      return this.$store.getters.isAdmin 
        || this.$store.state.auth.featureFlags?.avriSync?.orgIds?.includes(Number(this.$store.getters.organizationId))           
    }
  },
  methods: {    
    setToggle(ev){
      localStorage.setItem("avrisync-mvp", ev.value)

      if(this.$route.path.startsWith('/sessions2') && this.$route.path.endsWith('/manage')){
        window.location.reload()
        return  
      }

      this.$store.state.auth.avrisyncMvpToggle = ev.value

      if(this.$route.path.startsWith('/devices'))
        this.$router.push({name: 'Devices' + (this.toggle ? 3 : 2)})      
    },
    async logout(){
      await this.$store.dispatch(AUTH_LOGOUT); 
      if(this.$route.path == '/presentation')
        window.close()         
    },
    closeMenu: function(e){      
      const withinBoundaries = this.$refs.menu?.contains(e.target) || this.$refs.menuitems?.contains(e.target)      
      if(this.menuOpen && (e.keyCode == 27 || (e.keyCode == undefined && !withinBoundaries)))
        this.menuOpen = false;
    }
  },
  mounted: function(){
    window.addEventListener("keyup", this.closeMenu, false);
    window.addEventListener("click", this.closeMenu, false);
  },
  beforeDestroy: function(){
    window.removeEventListener("keyup", this.closeMenu, false);
    window.removeEventListener("click", this.closeMenu, false);
  }
}
</script>

<style lang="scss">

header.v2{
  height: var(--size-header);
  border-bottom: $bordermixin;
  background-color: white;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

  &.sticky{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 21;
  }

  .banner{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 21;
    height: var(--size-banner);
    background-color: #22292a;
    text-align: center;
    color: white;
    font-size: 12px;
    line-height: var(--size-banner);

    img{
      height: calc(var(--size-banner) - 2px);
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  
  .logo{
    height: 32px;
    width: 92px;
    margin: 8px 24px;
    float: left;
    background-image: url("../assets/inseadxr-logo.webp");
    background-size: contain;   
    background-repeat: no-repeat;     
  }
  a.logo:hover{
    background-color: white;
    background-image: url("../assets/inseadxr-logo.webp");
    background-size: contain;  
  }

  .slot{
    flex-grow: 1;
  }

  nav{
    font-size: 15px;
    line-height: 18px;
    float: right;
    margin: 8px 24px;
    display: flex;
    align-items: center;

    .wizard{
      width:18px;
      height:18px;
      cursor: pointer;      
      margin-right: 24px;
    }

    .menu{
      padding: 7px;

      &:hover{
          background: #E9F4EA;
          border-radius: 5px;
          cursor: pointer;
      }
    }

    .role{
      font-size: 12px;
      vertical-align: bottom;
      font-weight: bold;
      padding-left: 5px;
    }

    .menuitems{
      padding: 16px;
      position: absolute;
      right: 20px;
      top: 40px;
      background: white;
      border: $bordermixin;
      box-sizing: border-box;
      box-shadow: $shadowS;
      border-radius: 5px;
      z-index: 100;

      a {
        display: block;
        text-decoration: none !important;
      }
    }

    span.arrow{
      background-image: url("../assets/arrow-icon.svg");
      background-size: contain;
      background-position: top;
      background-repeat: no-repeat;
      width: 14px;
      height: 9px;
      display: inline-block;
      margin-right: 8px;      
    }
  }

  @media (min-width: 1440px){
      .banner{
        font-size: 14px;      
      }
      .logo{    
        height: 36px;
        width: 102px;
        margin: 8px 24px;
      }
      nav{
        margin: 10px 24px;
      }
  }

  @media (min-width: 1600px){ 
      .logo{    
        height: 38px;
        width: 112px;
        margin: 8px 24px;
      }
      nav{
        margin: 13px 24px;
      }
  }

  @media (min-width: 1920px){ 
    .banner{
      font-size: 16px;      
    }
    .logo{    
      width: 164px;
      height: 58px;
      margin: 6px 24px;
    }
    nav{
      margin: 19px 24px;

      .wizard{
        width:24px;
        height:24px;
      }
    }
  }
}

</style>

<template>
  <div id="app" :class="{topbanner: !$store.getters.isAdmin && !$route.path.startsWith('/dashboard')}">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div v-if="env != 'prod'" class="env-indicator" @dblclick="toggleAvrisDebug()">{{env}}</div>
    <div class="splash" v-if="splash">
      <div class="banner">
        Powered By <img src="./assets/avris-logo-white-LR.png">
      </div>
      <div class="box">
        <img src="./assets/inseadxr-logo.webp" />
        <h1>A New Reality by INSEAD XR</h1>
        <p>Our new and improved web app has moved to a different home. Here is the new link:</p>
        <a href="https://inseadxr.avris.io">INSEAD XR WEB APP</a>
        <p>Keep this link for the next time you come back</p>
        <br>
        <p style="font-size:12px;">If you need assistance, please contact your organizational administrator</p>
      </div>
    </div>
    <Header v-if="$store.getters.isAuthenticated && !$route.path.startsWith('/dashboard') && !v2" />
    <div v-if="v2" style="min-height: calc(100lvh - var(--size-header)); background-color: #f4f4f4;" class="themev2" :class="{dash: $route.path.startsWith('/dashboard')}">      
      <router-view style="margin-left: var(--size-sidebar); margin-top: var(--size-header); background: $bg-grey;" />
      <GettingStarted />
      <div class="loaderanimation" v-show="$store.state.showLoader"></div>
      <Sidebar2 style="min-height: calc(100lvh - var(--size-header))" v-if="!$route.path.startsWith('/dashboard')" />
      <Header2 :banner="!$store.getters.isAdmin" v-if="!$route.path.startsWith('/dashboard')" />      
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import {AUTH_LOGOUT} from "./store/actions/auth";
import axios from 'axios';
import Header from '@/components/Header.vue'
import Header2 from '@/components/Header2.vue'
import { setInteractionMode } from 'vee-validate';
import Sidebar2 from '@/components/Sidebar2.vue'
import GettingStarted from '@/components/GettingStarted.vue'

setInteractionMode('eager')

export default {
  name: 'App',
  components: { 
    Header,
    Header2,
    Sidebar2,
    GettingStarted
  },
  data: () => ({
  }),  
  computed:{    
    splash(){
      return this.env == 'beta'
    },
    env: function(){
      return process.env.VUE_APP_ENV
    },
    v2: function(){
      return this.$route.path.startsWith('/upcoming') 
          || this.$route.path.startsWith('/devices2') 
          || this.$route.path.startsWith('/devices3') 
          || this.$route.path.startsWith('/dashboard2') 
          || this.$route.path.startsWith('/sessions2') 
          || this.$route.path.startsWith('/xrlibrary') 
          || this.$route.path.startsWith('/users2')
          || this.$route.path.startsWith('/library')
          || this.$route.path == '/'
    }
  },
  methods: {
    toggleAvrisDebug(){
      if(this.env != 'prod'){
        this.$store.state.avrisdebug = !this.$store.state.avrisdebug
        console.log(`Debug ${this.$store.state.avrisdebug ? 'enabled' : 'disabled'}.`)
      }
    }    
    // async logout(){
    //   await this.$store.dispatch(AUTH_LOGOUT)
    //   // if(this.$route.name != 'Login')
    //   //   this.$router.push('/login')  
    //   if(this.$route)    
    // }
  },
  created: function () {    
    axios.defaults.baseURL = process.env.VUE_APP_API_URL + 'api/'
    const token = localStorage.getItem('auth-token')
    if (token)
      axios.defaults.headers.common['Authorization'] = "Bearer " + token
    
    let thisapp = this;
    axios.interceptors.response.use(
      response => response,
      err => {        
        if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
          // logout if unauthorized response, like expired token or such             
          thisapp.$store.dispatch(AUTH_LOGOUT)     
        }        
        throw err
      }      
    )

    // if logged out in another window (like presentation mode for example)
    window.addEventListener('storage', async (e) => {
        if (e.key == 'auth-token' && !e.newValue && this.$store.getters.isAuthenticated)
          this.$store.dispatch(AUTH_LOGOUT)
    })
  },
  async mounted(){
    if(this.$store.getters.isAuthenticated && !this.$store.state.auth.timeZone){   
      let profile = await axios.get('/auth/profile')
      this.$store.state.auth.timeZone = profile.data.timeZone      
    }
    if(this.$store.getters.isAuthenticated){
      await this.$store.dispatch('getExperiences')
      await this.$store.dispatch('getDrupalExperiences')
      await this.$store.dispatch('getFeatureFlags')
    }    
  }
};
</script>

<style lang="scss">

@import '~vue-good-table/dist/vue-good-table.css';
@import "@/assets/css/main.scss";
@import "@/assets/css/select.scss";

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;

  .splash{
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    z-index: 1000000;
    background-image: url(assets/betasplash.svg);
    background-size: cover;
    background-color: #f8f8f8;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

    .box {      
      width: 460px;
      height: 424px;
      flex-grow: 0;
      background-color: white;
      background-image: linear-gradient(2deg, rgba(0, 104, 75, 0.5) 2%, rgba(0, 104, 75, 0.1) 33%, rgba(0, 104, 75, 0) 57%);
      text-align: center;
      box-shadow: 0 0 12px 0 rgba(2, 18, 55, 0.16);
      border-radius: 5px;
      padding: 32px;

      img{
        height: 58px;    
        margin-bottom:40px;
      }

      h1{
        font-size: 26px;
      }
      p{
        font-size: 16px;
        line-height: 1.25;
      }
      a{
        color:#4cad82;
        font-weight: bold;
        font-size: 16px;
      }
    }
    

    .banner{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 21;
      height: 40px;
      background-color: #22292a;
      text-align: center;
      color: white;
      font-size: 16px;
      line-height: 40px;

      img{
        height: 38px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }

  .env-indicator{
    position: fixed;
    top: 0;
    left: 0;
    padding: 3px;
    background-color: red;
    color: white;
    font-size: 10px;
    z-index: 100;
  }

  .loaderanimation{
    position: fixed;
    left: var(--size-sidebar);
    top: var(--size-header);
    right:0;
    bottom:0;    
    z-index: 21;
    background-color: white;
    
    &:after{
      content: '';
      width: 150px;
      height: 150px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-75px, -75px);
      box-shadow: inset 0 0 0 5px #fff;
      background: url(assets/SYMBOL_White_150.gif) center center no-repeat white;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

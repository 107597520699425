export function getContentComponentType(item) {
    if(item.type == 'seg')
        return 'SegmentItem'
    if (item.type == 'hotspots')
        return 'Hotspot'    // Hotspot can contain multiple HotspotItem, but those are fixed so no need actually to map them
    if (item.type == 'list')
        return 'ListItem'
    if (item.type == 'conditional')
        return 'ConditionalItem'
    if (item.type == 'vid')
        return 'VideoItem'
    if (item.type == 'txt')
        return 'TextItem'
    if (item.Q !== undefined)
        return 'QuestionItem'
    // if (item.optionId !== undefined)     // OptionItem only appears in QuestionItem component as children so no need to dnyamically resolve
    //     return 'OptionItem'
    if (item.type == 'pic')
        return 'PictureItem'

    return undefined    
}

export function humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function getEmptyContent(type) {    
    if (type == 'seg')
        return {
            type: "seg",
            id: undefined,
            seg: undefined,
            txt1: undefined,
            txt2: undefined,
            hotspotsLabelName: undefined,
            icon: undefined,
            segJson: {
                stereo: true,
                type: 'seg',
                items: []
            },
            condition: undefined
        }
    if (type == 'conditional')
        return {
            type: "conditional",
            id: undefined,
            txt1: undefined,
            txt2: undefined,
            icon: undefined,
            list: []
        }
    if (type == 'list')
        return {
            type: "list",
            id: undefined,
            bg: undefined,
            txt1: undefined,
            txt2: undefined,
            icon: undefined,
            list: []
        }
    if (type == 'pic')
        return {
            $type: 'PicData, Assembly-CSharp',
            type: "pic",
            id: undefined,
            fname: '',
            duration: undefined,
            path: undefined
        }
    if (type == 'vid')
        return {
            $type: 'VideoData, Assembly-CSharp',
            type: "vid",
            id: undefined,
            fname: '',
            duration: undefined,
            path: undefined,
            heatmap: undefined,
            heatmapWebVid: undefined
        }
    if (type == 'txt')
        return {
            $type: 'TextData, Assembly-CSharp',
            type: 'txt',
            id: undefined,
            caption: '',
            text: '',
            action: ''        
        }
    if (type == 'question')
        return {
            $type: 'QuestionData, Assembly-CSharp',
            id: undefined,
            type: undefined,
            Q: '',
            Qdashboard: undefined,
            showValueAverage: false,
            valueAverageLabel: undefined,
            Answers: undefined,
            showAnswer: undefined,
            path: undefined,
            maxOptions: undefined,
            ranking: undefined,
            items: []
        }
    if (type == 'option')
        return {
            optionId: undefined,
            image: undefined,
            text: '',
            textDashboard: undefined
        }
    if (type == 'hotspot')
        return {
            $type: 'HotspotsData, Assembly-CSharp',
            type: 'hotspots',
            id: undefined,
            textureSize: undefined,
            hintImage: undefined,
            mapImage: undefined,
            root: undefined,
            parentId: undefined,
            homeId: undefined,
            showHome: undefined,
            showTimer: undefined,
            maxClicks: undefined,
            text: undefined,
            path: undefined,
            items: []
        }
    if (type == 'hotspotitem')
        return {
            optionId: undefined,
            image: undefined,
            clickImage: undefined,
            countable: undefined,
            clicksound: undefined,
            text: '',
            rect: {
                x: 0,
                y: 0,
                width: 0,
                height: 0
            }
        }

    return undefined
}

export function generateEditorId() {
    return Math.random().toString(36).substring(2)
}

export function formatSessionStatus(status){
    switch(status){
        case 0: return 'Not Started';
        case 1: return 'Live';
        case 2: return 'Paused';
        case 3: return 'Self Paced';
        case 4: return 'Finished';
      }
}

export function formatWorkflowStatus(status){
    switch(status){
        case 0: return 'Requested';
        case 1: return 'Rejected';
        case 2: return 'Updated';
        case 3: return 'Approved';
        case 4: return 'Ready';
      }
}

export function formatProgramType(status){
    switch(status){
        case 0: return 'Degree';
        case 1: return 'Executive';
        case 2: return 'Demo';
      }
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

// new Date(d.toLocaleString('en-US', {timeZone: 'Europe/Bucharest'}))

/* @return A timezone offset in hours */
export function getTimeZoneOffset(timeZone = 'UTC', date = new Date()){
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 6e4 / 60;
}

export function toTimeZone(date_, timeZone){
    return timeZone ? new Date(date_.toLocaleString('en-US', {timeZone: timeZone})) : date_
}

export function formatDate(date_, timeZone){
    const d = timeZone ? new Date(date_.toLocaleString('en-US', {timeZone: timeZone})) : date_

    const monthName = months[d.getMonth()]
    const dayName = days[d.getDay()]
    const date = d.getDate()
    const hours = d.getHours()
    const minutes = d.getMinutes()
    const year = d.getFullYear()
    const currentYear = toTimeZone(new Date(), timeZone).getFullYear()

    return `${dayName},${currentYear != year ? ` ${year}` : ''} ${monthName} ${date}  ${hours}:${minutes.toString().padStart(2,'0')}`
}
export function formatDates(date1_, date2_, timeZone){
    const d1 = timeZone ? new Date(date1_.toLocaleString('en-US', {timeZone: timeZone})) : date1_
    const d2 = timeZone ? new Date(date2_.toLocaleString('en-US', {timeZone: timeZone})) : date2_

    // console.log(timeZone)
    // console.log(date1_)
    // console.log(date2_)
    // console.log(d1)
    // console.log(d2)

    const currentYear = toTimeZone(new Date(), timeZone).getFullYear()

    const monthName1 = months[d1.getMonth()]
    const dayName1 = days[d1.getDay()]
    const year1 = d1.getFullYear()
    const date1 = d1.getDate()
    const hours1 = d1.getHours()
    const minutes1 = d1.getMinutes()

    const monthName2 = months[d2.getMonth()]
    const dayName2 = days[d2.getDay()]
    const year2 = d2.getFullYear()
    const date2 = d2.getDate()
    const hours2 = d2.getHours()
    const minutes2 = d2.getMinutes()

    const suffix = monthName1 == monthName2 && date1 == date2 && year1 == year2
        ? ''
        : `${dayName2},${(currentYear != year2 && year1 != year2) ? ` ${year2}` : ''} ${monthName2} ${date2}`
    
    return `${dayName1},${currentYear != year1 ? ` ${year1}` : ''} ${monthName1} ${date1}  ${hours1}:${minutes1.toString().padStart(2,'0')} - ${hours2}:${minutes2.toString().padStart(2,'0')}  ${suffix}`
}

import axios from 'axios'
export async function validateS3Files(component){
    component.editorStructure.fileList = []
    component.loading = true
    let allFilesValid = true
    for (const field of component.fileFields) {
        try {
            if (component.structure[field]) {
                let resp = await axios.head(process.env.VUE_APP_PUBLIC_BLOB + component.structure[field],
                    {
                        transformRequest: (data, headers) => {
                            delete headers.common['Authorization'];
                        }
                    })
                component.editorStructure.fileList.push({
                    key: component.structure[field],
                    size: resp.headers["content-length"],
                    eTag: resp.headers["etag"],
                    lastModified: new Date(resp.headers["last-modified"]).toISOString()
                })
            }
        }
        catch (e) {
            component.$refs.snackbar.show(`"${field}" problem with file`)
            component.$refs.observer.refs[field].setFlags({ invalid: true })
            component.$refs.observer.errors[field].unshift("File not found on S3 storage.")
            allFilesValid = false
        }
    }
    component.loading = false
    return allFilesValid
}
<template>  
  <div class="w560 themev2">

    <div class="w300" v-if="error">
        <div class="formerror" v-if="error">{{ error }}</div>
        <br>
    </div>

    <span class="spinner profilespinner" v-if="loading" />

    <div class="w300">
      <h1>Profile details</h1>
    </div>

    <div v-if="(this.$store.getters.isAdmin || this.$store.getters.isOrgAdmin) && env != 'prod'"> 
      <div class="w300" style="padding-bottom:10px;">
        <label>AvriSync time range on Organization Level</label>
        <div style="clear:both;">
        This feature is just a dev-only mockup for showcasing the possible UI controls.
        </div>
      </div>

      <div class="w300" style="padding-bottom:20px;">
        <label>Version 1: one slider per timespan:</label>
      </div>

      <div v-for="(slider, idx) in sliders" :key="'sl'+idx" style="display:flex; margin: 40px auto; width:420px;" class="avrisync-slider">
        <div style="flex-grow:1; color:#777;">{{sliders[idx][0].toString().padStart(2, '0')}}:00</div>
        <VueSlider style="width:300px; flex-basis: 300px;" 
          v-model="sliders[idx]" 
          :enable-cross="true"
          :min="0"
          :max="24"
          :min-range="1"
          :max-range="24"
          :adsorb="false"
          :marks="[0,4,8,12,16,20,24]"
        >
          <template v-slot:process="{ /*start, end,*/ style/*, index*/ }">
            <div class="vue-slider-process custom-class" :style="[style/*, customStyle*/]">              
              <div style="text-align:center; transform:translateY(-20px); font-weight:bold;">{{sliders[idx][1]-sliders[idx][0]}}h</div>
            </div>
          </template>
        </VueSlider>
        <div style="flex-grow:1; text-align:right; color:#777;">{{sliders[idx][1].toString().padStart(2, '0')}}:00</div>
      </div>
      <div class="w300">
        <button style="width:100%;" @click="addSlider">add slider</button>
      </div>
      <div class="w300" style="padding-bottom:20px; padding-top:20px;">
        <label>Version 2: Multiple ranges in one slider:</label>
      </div>

      <div style="display:flex; margin: 40px auto; width:420px;" class="avrisync-slider">
        <!-- <div style="flex-grow:1; color:#777;">{{sliders[idx][0].toString().padStart(2, '0')}}:00</div> -->
        <div style="flex-grow:1; color:#777;">00:00</div>
        <VueSlider style="width:300px; flex-basis: 300px;" 
          v-model="slider" 
          :enable-cross="true"
          :min="0"
          :max="24"
          :min-range="0"
          :max-range="24"
          :adsorb="true"
          :process="mapSliders"
          :marks="slider"
        >
          <template v-slot:process="{ /*start, end,*/ style, index }">
            <div class="vue-slider-process custom-class" :style="[style/*, customStyle*/]">
              <div style="text-align:center; transform:translateY(-20px); font-weight:bold;">{{rangeDuration(index)}}h</div>
            </div>
          </template>
        </VueSlider>        
        <!-- <div style="flex-grow:1; text-align:right; color:#777;">{{sliders[idx][1].toString().padStart(2, '0')}}:00</div> -->
        <div style="flex-grow:1; text-align:right; color:#777;">24:00</div>
      </div>
      <div class="w300" style="margin-bottom:40px;">
        <button style="width:100%;" @click="addRange">add range</button>
      </div>
    </div>

    <div v-if="avrisyncMvpToggleAvailable" style="margin-bottom:40px;">
      <div class="w300" style="padding-bottom:10px;">
        <label>AvriSync MVP feature flag: </label>
        <ToggleButton style="margin: 8px 0 0 10px;" title="Toggle AvriSync" :disabled="loading" :labels="false" v-model="toggle" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" :sync="true" @change="setToggle" /> 
      </div>
    </div>

    <ValidationObserver ref="veeform" v-slot="{ invalid, handleSubmit}" tag="div" class="w300" v-if="$store.state.auth.status != 'register_success'">
      <form ref="form" method="POST" @submit.prevent="handleSubmit(onSubmit)" spellcheck="false" :class="{disabled: $store.getters.loading || loading}">

        <label for="organization">Organization</label>
        <input v-model="form.organization" type="text" style="color: #80878B;" novalidate autocomplete="off" disabled>

        <!-- <ValidationProvider name="email" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }"> -->
          <label for="email">Email</label>
          <input v-model="form.email" disabled name="email" type="email" novalidate>          
          <!-- <input v-model="form.email" disabled name="email" type="email" novalidate :class="classes" :disabled="$store.getters.loading || loading">           -->
          <!-- <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider> -->

        <ValidationProvider name="firstName" rules="required" tag="div" class="inputrow"  v-slot="{ classes, errors }">
          <label for="firstName">First name</label>
          <input v-model="form.firstName" name="firstName" type="text" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading || loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="lastName" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="lastName">Last name</label>
          <input v-model="form.lastName" name="lastName" type="text" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading || loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>       

        <ValidationProvider name="phone" rules="required|phone" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="phone">Phone number</label>
          <input v-model="form.phone" name="phone" type="tel" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading || loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="timezone" rules="required" tag="div" ref="timeZoneValidator" class="inputrow" v-slot="{ classes, errors }">
          <label for="timezone">Time zone</label>
          <!-- <select class="select-css" v-model="form.timeZone" :class="classes" style="height: 44px;" :disabled="$store.getters.loading || loading">
            <option v-for="zone in timeZones" :key="zone" :value="zone">{{zone}}</option>
          </select> -->
          <vSelect
              :disabled="$store.getters.loading || loading" 
              :class="classes"
              placeholder="Select preferred time zone"
              name="timezone"
              style="width:100%; clear:both;"
              :options="timeZones" 
              @close="validateSelectedTimeZone"      
              :clearable="false"
              v-model="form.timeZone"  
              :reduce="tz => tz.id" 
              label="id"        
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template>
            <template #option="tz">              
              {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
            </template>
            <template #selected-option="tz">
              {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
            </template>   
          </vSelect>
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>
     
        <br>
        <h3>Change password</h3>

        <ValidationProvider name="newPassword" mode="aggressive" ref="newPassword" rules="password" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="newPassword">New Password</label>
          <input v-model="form.newPassword" name="newPassword" type="password" novalidate :class="classes" placeholder="" :disabled="$store.getters.loading || loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="newPassword2" rules="confirmed:newPassword|required_if:newPassword" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="newPassword2">Repeat new password</label>
          <input v-model="form.newPassword2" name="newPassword2" type="password" novalidate :class="classes" placeholder="" :disabled="$store.getters.loading || loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>
        <br>

        <ValidationProvider name="oldPassword" ref="oldPassword" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="oldPassword">Current Password</label>
          <input v-model="form.oldPassword" name="oldPassword" type="password" novalidate :class="classes" placeholder="Password required to update profile info" :disabled="$store.getters.loading  || loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>        
        <button class="full" type="submit" :class="{disabled: invalid}" :disabled="invalid">Save</button>
        
        <span class="spinner" v-if="$store.getters.loading" />

        <div class="formerror" v-if="$store.state.auth.error">{{$store.state.auth.error}}</div>
        <div class="formsuccess" v-if="$store.state.auth.status == 'profile_update_success'">
          <span class="checkmark"> </span> Profile updated successfully
        </div>

      </form>
    </ValidationObserver>  
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {PROFILE_UPDATE_REQUEST} from "../store/actions/auth"
import axios from 'axios'
import '@/veevalidate.js'
import vSelect from 'vue-select'
import { getTimeZoneOffset } from '@/utils.js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'Profile',
  data: function(){
    return {
      slider: [0,6],      
      sliders: [
        [0,10]
      ],
      form: {
        organization: 'None',
        firstName: '',
        lastName: '',
        email: '',        
        phone: '',
        oldPassword: '',
        newPassword: '',
        newPassword2: '',
        timeZone: ''
      },
      error: undefined,
      loading: true,
      toggle: this.$store.getters.avrisyncMvp
    }
  },
  computed: {  
    env(){
      return process.env.VUE_APP_ENV
    },
    timeZones(){
      return Intl.supportedValuesOf('timeZone').map(tz => ({id: tz, offset: getTimeZoneOffset(tz)}))
    },
    avrisyncMvpToggleAvailable(){      
      return this.$store.getters.isAdmin 
        || this.$store.state.auth.featureFlags?.avriSync?.orgIds?.includes(Number(this.$store.getters.organizationId))           
    }
  },
  methods: {  
    setToggle(ev){
      localStorage.setItem("avrisync-mvp", ev.value)
      this.$store.state.auth.avrisyncMvpToggle = ev.value
    },
    addSlider(){
      this.sliders.push([20, 24])
    },
    addRange(){
      this.slider.push(20, 24)
    },
    rangeDuration(idx){
      let ranges = this.mapSliders(this.slider)
      return ranges[idx][1]-ranges[idx][0]
    },
    // mapMarks(){
    //   let ranges = this.mapSliders(this.slider)
    //   return ran
    // },
    mapSliders(dots){
      let ret = []
      for (let i = 0; i < dots.length; i+=2) {
        ret.push([dots[i], dots[i+1]])        
      }
      return ret
      // return [
      //   [dots[0], dots[1]],
      //   [dots[2], dots[3]]
      // ]
    },
    handleRangesChange(x){
      console.log(x)
    },
    validateSelectedTimeZone(){
      return this.$refs.timeZoneValidator.validate()
    },
    async onSubmit() { 
      await this.$store.dispatch(PROFILE_UPDATE_REQUEST, this.form) 
      this.form.oldPassword = undefined;
      this.form.newPassword = undefined;
      this.form.newPassword2 = undefined;
      this.$refs.veeform.reset();
    }
  },
  components: {
    ValidationProvider, 
    ValidationObserver,
    vSelect,
    VueSlider
  },
  created: async function () {
    try{    
      let resp = await axios({ url: "auth/profile", method: "GET" });
      this.form.firstName = resp.data.firstName;
      this.form.lastName = resp.data.lastName;
      this.form.email = resp.data.email;
      this.form.phone = resp.data.phone;
      this.form.organization = resp.data.organization;
      this.form.timeZone = resp.data.timeZone;
    }
    catch(err){
      this.error = err;
    }
    finally{
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
form h3{
  margin-bottom: 15px;
  font-weight: normal;
}

.profilespinner{
  top: 130px;
}

.avrisync-slider{
  .vue-slider-marks{
    color: #ccc;
  }
  .vue-slider-process {
    background-color: #57af80;
  }
  .vue-slider-dot-tooltip-inner{
    background-color: #57af80;
    border-color: #57af80;
  }
}
</style>